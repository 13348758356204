@import url(https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap);
.container-custom {
  overflow-y: scroll;
  height: 64vh;
}
.container::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}
.container::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* color of the tracking area */
}
.container::-webkit-scrollbar-thumb {
  background-color: rgba(54, 54, 54, 0.466); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 3px solid rgba(78, 78, 78, 0.452); /* creates padding around scroll thumb */
}
.inprogress-card {
  background-color: #aad6b9;
  border-radius: 15px;
  height: 90%;
}
.darkCard {
  opacity: 0.9;
}
.topicName {
  color: #333333;
  font-size: 25px;
  font-weight: 700;
}
.totalQuestion {
  color: #4f4f4f;
  font-weight: 700;
}
.notstarted-card {
  background-color: #aad0f6;
  border-radius: 15px;
  height: 90%;
}

.percentDone {
  color: #4f4f4f;
}

.container-custom2 {
  width: 40vw;
  min-width: 290px !important;
}

.hvr-grow {
  vertical-align: middle;
  transform: perspective(0.5px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.05);
}

@media screen and (max-height: 650px) {
  .container-custom {
    height: 56vh;
  }
}

@media screen and (max-height: 750px) {
  .container-custom {
    height: 54vh;
  }
}

:root {
	/* These are the breakpoints, usage: for media query  */
	--table-xl-width: 1140px;
	--table-lg-width: 960px;
	--table-md-width: 720px;
	--table-sm-width: 540px;
}

.Toastify__toast-body {
	color: white;
	font-family: "Ubuntu Mono", monospace;
	font-weight: 600;
	padding: 0 0.5rem;
}

.Toastify__toast-body .toast-subtitle {
	font-weight: 400;
	font-size: small;
}

/* Marked Complete */
.Toastify__toast.toast-Done {
	background: #27ae60;
}

.Toastify__toast.toast-Done .Toastify__progress-bar {
	background: #c8e6c9;
}

/* Marked Incomplete */
.Toastify__toast.toast-Incomplete {
	background: #007bff;
}

.Toastify__toast.toast-Incomplete .Toastify__progress-bar {
	background: #aad0f6;
}

/* Notes section */

.note-container {
	background: white;
	position: absolute;
	display: block;
	width: 50%;
	padding: 2% 1%;
	top: 30%;
	right: 25%;
	border: 2px solid black;
	border-radius: 0.5em;
}

.question-title {
	width: 100%;
	background: rgb(243, 243, 243);
	margin-bottom: 0.5em;
	font-weight: bold;
	resize: none;
	font-size: 1.2em;
	border: 0.5px solid black;
	border-radius: 5px;
	padding: 0.5em 0.8em;
	word-wrap: break-word;
}

.note-section {
	background: rgb(255, 255, 255);
	display: none;
	border: 2px solid #27ae60;
	border-radius: 5px;
	position: relative;
	margin-bottom: 0.5em;
	width: 100%;
	padding: 0.5em 0.8em;
	resize: none;
	height: 10em;
}
.note-section:focus-visible {
	outline: none;
}

::-webkit-input-placeholder {
	color: black;
	opacity: 0.5;
}

:-ms-input-placeholder {
	color: black;
	opacity: 0.5;
}

::-ms-input-placeholder {
	color: black;
	opacity: 0.5;
}

::placeholder {
	color: black;
	opacity: 0.5;
}
.button-container {
	display: flex;
	align-items: right;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.note-exit {
	display: none;
	position: relative;
	width: 5em;
	background: #dc3545;
	margin-right: 0.3em;
	border-radius: 0.3em;
	color: white;
}

.note-save {
	display: none;
	position: relative;
	background: #27ae60;
	width: 5em;
	border-radius: 0.3em;
	margin-right: 0.3em;
	color: white;
}

.note-area {
	display: none;
	height: 10em;
	width: 10em;
	background: rgba(255, 255, 255, 0.1);
	-webkit-backdrop-filter: blur(2px);
	        backdrop-filter: blur(2px);

	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
}

#tooltip-top > .tooltip-inner {
	background-color: #fff;
	color: green;
	border: 1px solid #062e56;
}

#tooltip-top > .tooltip-arrow {
	border-top: 5px solid #062e56;
}
.dark-table {
	color: white;
}

.question-link:hover {
	color: rgb(0, 115, 192);
}
.header-rand {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.random {
	margin-bottom: 25px;
}
.random > a {
	padding: 10px 15px;
	border-radius: 10px;
	background-color: #27ae60;
	color: white;
	text-decoration: none;
}
.random > a:hover {
	color: white;
	background-color: #1a8647;
}
.pick-random-btn {
	z-index: 0 !important; /*To Override z-index set by Bootstrap*/
}

.completed-questions {
	margin: 0px;
	background: rgb(200, 230, 201);
	font-size: 18px;
	font-weight: 100;
	padding: 5px 8px;
	margin-left: auto;
}

.topic-input-container {
	display: flex;
	width: 100%;
	max-width: 1140px;
	max-width: var(--table-xl-width);
	padding: 0px 15px;
	box-sizing: border-box;
	align-items: center;
}
.container-custom2 {
	margin: 0px;
	padding-left: 0px;
}
.container-custom2 > .mb-4 {
	padding: 10px 0px;
	margin-bottom: 0px !important;
}

@media screen and (max-width: 1199px) {
	.topic-input-container {
		max-width: 960px;
		max-width: var(--table-lg-width);
	}
}
@media screen and (max-width: 991px) {
	.topic-input-container {
		max-width: 720px;
		max-width: var(--table-md-width);
	}
}
@media screen and (max-width: 767px) {
	.container-custom {
		padding-bottom: 50px;
	}
	.search-input-container {
		min-width: 300px !important;
	}
	.container-custom2 > .mb-4 {
		margin-left: 0px !important;
	}
	.container-custom2 {
		width: 100% !important;
	}
	.topic-input-container {
		flex-direction: column;
		align-items: flex-start;
	}
	.container-custom2 > .topic-input-container {
		flex-direction: column;
	}
	.topic-input-container {
		max-width: 540px;
		max-width: var(--table-sm-width);
		margin-bottom: 10px;
	}
	.completed-questions {
		margin-left: 0px;
	}
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 45px;
	background-color: #27ae60;
	transition: all 0.4s ease-in-out;
}

.footer-toggle {
	display: flex;
	justify-content: space-evenly;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: relative;
}

.app-heading {
	font-weight: 700;
	color: #343a40;
	font-size: 50px;
}
.App {
	position: absolute;
	height: 100vh;
	width: 100vw;
	transition: all 0.3s ease-in-out;
}
.dark {
	background-color: #151515;
	color: white;
}

@media screen and (max-width: 480px) {
	.app-heading {
		font-size: 30px;
	}
	.app-heading2 {
		font-size: 20px;
	}
}

.emojiFix {
	font-weight: 100 !important;
}

body {
  margin: 0;
  overflow-y: hidden;
  font-family: 'Ubuntu Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
