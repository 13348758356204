.app-heading {
	font-weight: 700;
	color: #343a40;
	font-size: 50px;
}
.App {
	position: absolute;
	height: 100vh;
	width: 100vw;
	transition: all 0.3s ease-in-out;
}
.dark {
	background-color: #151515;
	color: white;
}

@media screen and (max-width: 480px) {
	.app-heading {
		font-size: 30px;
	}
	.app-heading2 {
		font-size: 20px;
	}
}

.emojiFix {
	font-weight: 100 !important;
}
